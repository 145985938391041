import React, { useState, useEffect } from "react";
import Scrollspy from "react-scrollspy";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Header = ({ data }) => {
  const [navbar, setNavbar] = useState(false);
  const [active, setActive] = useState("");
  const [list, setList] = useState([]);
  const header = data?.header;

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    if (data?.header) {
      const labels = data?.header?.menu.map((item) => item.path);
      setList(labels);
    }
    window.addEventListener("scroll", changeBackground);

    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  const mobileNav = (e) => {
    setActive(e?.id);
  };

  if (list.length === 0) return null;
  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "civsav_tm_topbar animate" : "civsav_tm_topbar"}>
        <div className="in">
          <div className="topbar_inner">
            <div className="logo">
              <Link to="/">
                <div
                  style={{
                    height: header?.logo?.height,
                    width: header?.logo?.width,
                  }}
                >
                  <GatsbyImage
                    image={header.logo?.image?.asset?.gatsbyImageData}
                    alt={data?.header?.logo?.alt}
                  />
                </div>
              </Link>
            </div>
            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={list}
                currentClassName="current"
                offset={-88}
              >
                {data?.header?.menu.map((item, index) => (
                  <li className={`${index === 0 ? "current" : ""}`} key={index}>
                    <a href={`#${item.path}`}>
                      <span
                        className="first"
                        style={{ color: item?.label?.color?.hex }}
                      >
                        {item.label?.text}
                      </span>
                      <span
                        className="second"
                        style={{ color: item?.hover?.hex }}
                      >
                        {item.label?.text}
                      </span>
                    </a>
                  </li>
                ))}
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
      {/* /TOPBAR */}

      <div className="mobile-menu-wrapper">
        <Scrollspy
          className="mobile_menu-icon"
          items={list}
          currentClassName="current"
          offset={-65}
          onUpdate={mobileNav}
        >
          {data?.header?.menu.map((item, index) => (
            <li className={`${index === 0 ? "current" : ""}`} key={index}>
              <a href={`#${item.path}`}>
                <div
                  dangerouslySetInnerHTML={{ __html: item.svg }}
                  className="flex justify-center"
                  style={{
                    color:
                      item.path === active
                        ? item?.hover?.hex
                        : item?.label?.color?.hex,
                  }}
                />
                <span
                  style={{
                    color:
                      item.path === active
                        ? item?.hover?.hex
                        : item?.label?.color?.hex,
                  }}
                >
                  {item.label?.text}
                </span>
              </a>
            </li>
          ))}
        </Scrollspy>
      </div>
      {/* End mobile-menu */}
    </>
  );
};

export default Header;
